import { PortalType } from './types';
import { PortalDomain, PortalLoggerGroup } from './config';
import {
  getRelease,
  getAWSLogger,
  getPortalType,
  configFunctions,
} from './helpers';

const env = process.env;

const portalType = getPortalType();

const buildVersion = env.REACT_APP_CODEBUILD_BUILD_NUMBER;
const serverEnvironment = env.REACT_APP_SERVER_ENV;

const sentry = {
  config: env.REACT_APP_SENTRY,
};

export const Config = {
  /** The current build version of the application. Available in the Cloud. */
  buildVersion,
  /** Describes the server environment (e.g., development, production). */
  serverEnvironment,
  /** Utility functions related to configuration. */
  helpers: configFunctions,
  /** Base URL for API. */
  baseURL: env.REACT_APP_BASE_URL,
  /** VAPID key for push notification service configuration. */
  vapidKey: env.REACT_APP_VAPID_KEY,
  /** Stripe API key for Stripe Elements. */
  stripeKey: env.REACT_APP_STRIPE_KEY,
  /** Boolean flag to enable or disable certain features in the app based on environment settings. */
  isEnabled: env.REACT_APP_IS_ENABLED === 'true',
  /** Base URL for long queries API calls. */
  baseBackendURL: env.REACT_APP_BASE_BACKEND_URL,
  /** Checks if the Node environment is set to production. */
  isNodeProduction: env.NODE_ENV === 'production',
  /** Encryption key for local storage values. */
  cryptoKey: env.REACT_APP_CRYPTO_KEY || 'secret',
  /** Ably default channel name. */
  ablyChannel: env.REACT_APP_ABLY_CHANNEL || 'channel',
  /** Enables debugging of HTML/PDF content. */
  isDebugHTMLEnabled: env.REACT_APP_DEBUG_HTML === 'true',
  /** Specific default hospital ID for the Pet Owner portal. Only for local testing. */
  basePetOwnerPortalHospitalId: env.REACT_APP_PO_HOSPITAL_ID,
  /** Controls whether the idle timer is disabled, preventing auto-logout during inactivity. */
  isIdleTimerDisabled: env.REACT_APP_IDLE_DISABLED === 'true',
  hideEmergencyCallOutcome: env.REACT_APP_HIDE_EMERGNCY_CALL_OUTCOMES,
  showCallingPopupAlert: env.REACT_APP_SHOW_CALLING_POPUP_ALERT || 735,
  /** Sentry configuration. */
  sentry: {
    ...sentry,
    ...getRelease(sentry.config, serverEnvironment, buildVersion),
  },
  /** AWS configuration. */
  aws: {
    logger: {
      ...getAWSLogger(),
      group: `${PortalLoggerGroup[portalType]}-${serverEnvironment}`,
    },
  },
  /** Identifies the type of portal (e.g., team, hospital, pet-owner) currently in use. */
  portalType: {
    raw: portalType,
    isTeam: portalType === PortalType.Team,
    isHospital: portalType === PortalType.Hospital,
    isPetOwner: portalType === PortalType.PetOwner,
  },
  /** Firebase configuration specific to the portal type, parsed from a JSON string in the environment variables. */
  firebase: {
    config: JSON.parse(
      (portalType === PortalType.Team
        ? env.REACT_APP_FIREBASE_TEAM
        : env.REACT_APP_FIREBASE_HOSPITAL)! // TODO: fix for PO portal
    ),
  },
  /** Google services configuration. */
  google: {
    placeKey: env.REACT_APP_GOOGLE_PLACE_KEY,
    clientId: env.REACT_APP_GOOGLE_CLIENT_ID,
    authRedirectURI: env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI?.replace(
      'triage',
      PortalDomain[portalType]
    ),
  },
  /**
   * Overrides for API base URLs, providing flexibility to specify different endpoints for local environments.
   * These overrides allow local testing of various API functionalities by pointing to alternative service URLs.
   * - `base`: Override for the main API base URL.
   * - `talk`: Override for the communications-related API.
   * - `payments`: Override for the payment processing API.
   * - `analytics`: Override for the analytics and reporting API.
   * - `longQueries`: A list of endpoints that require extended timeouts, used for queries expected to exceed 30 seconds.
   *    This is especially useful for handling long-running database queries or data processing tasks that exceed default API timeout settings.
   */
  apiOverride: {
    base: env.REACT_APP_OVERRIDE_BASE_URL,
    talk: env.REACT_APP_OVERRIDE_TALK_URL,
    payments: env.REACT_APP_OVERRIDE_PAYMENTS_URL,
    analytics: env.REACT_APP_OVERRIDE_ANALYTICS_URL,
    longQueries: env.REACT_APP_LONG_QUERIES?.split(','),
  },
};

export { PortalType };
