import { useSelector } from 'react-redux';
import { useMemo, useState, useEffect } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import {
  Row,
  Col,
  Dialog,
  InputField,
  FlexLayout,
  StandardButton,
  RadioFieldGroup,
  ClientFormProps,
} from '@gv/triage-components';

import { Config } from 'config';
import { CallOutcomeType } from 'types';
import { getClientEmails } from 'utils/helpers';
import { RootState, useAppSelector } from 'store';
import { useCorporateOptions } from 'hooks/refer';
import { selectCurrentTaskForm } from 'store/api/action-queue/selectors';
import {
  useFormEntities,
  callOutcomeModel,
  CheckProtocolsInfo,
  CaseFormTextOutProps,
} from 'components/cases/form';

import { surveyModal } from '../../config';
import { CallOutcomeRefer } from '../refer';
import { CallOutcomeAction } from '../action';

export const CallOutcomeMain = (props: CaseFormTextOutProps) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const { client, hospital, hospitalId } = useFormEntities();

  const { isShowFeedback } = useSelector(
    (state: RootState) => state.analyticsExport
  );
  const { corporateGroupOptions, isCorporateGroupFetching } =
    useCorporateOptions(hospitalId);

  const {
    blockedCallOutcomeTypes,
    hospitalCallOutcomeTitles,
    hasAccessToTransferBackline,
  } = useMemo(
    () => ({
      hospitalCallOutcomeTitles:
        Config.helpers.getCallOutcomeTitles(hospitalId),
      blockedCallOutcomeTypes:
        Config.helpers.getBlockedCallOutcomeTypes(hospitalId),
      hasAccessToTransferBackline: Config.helpers.featureAvailableForHospital(
        hospitalId,
        'CALL_OUTCOME_TRANSFER_TO_BACKLINE'
      ),
    }),
    [hospitalId]
  );

  const caseForm = useAppSelector(selectCurrentTaskForm);

  const clientProps = useMemo<Partial<ClientFormProps>>(() => {
    if (!client) {
      return {};
    }
    return {
      emails: getClientEmails(client),
    };
  }, [client]);

  useEffect(() => {
    if (caseForm?.client === undefined) {
      setFieldValue(
        surveyModal.feedback_email,
        clientProps?.emails && clientProps?.emails[0]
      );
    } else {
      setFieldValue(surveyModal.feedback_email, caseForm?.client?.email);
    }
  }, [clientProps]);

  const isEdit = location.pathname.includes('consultation/edit');
  const [isAdminAlert, setAdminAlert] = useState(false);
  return (
    <FlexLayout gap={24} flexDirection="column">
      <div>
        <RadioFieldGroup
          label="Select call outcome"
          name={callOutcomeModel.type}
          onChange={(event) => {
            setAdminAlert(false);
            if (event === CallOutcomeType.Administrative) {
              setAdminAlert(true);
            }
          }}
          items={[
            {
              label: 'Appointment',
              value: CallOutcomeType.Appointment,
              hasAccess: hospital && hospital.speciality !== 'Emergency',
            },
            { label: 'Emergency', value: CallOutcomeType.Emergency },
            {
              label: 'Administrative',
              value: CallOutcomeType.Administrative,
              hasAccess: !blockedCallOutcomeTypes?.includes(
                CallOutcomeType.Administrative
              ),
            },
            {
              value: 'transferToBackline',
              hasAccess: hasAccessToTransferBackline,
              label:
                hospitalCallOutcomeTitles?.transferToBackline ??
                'Transfer to backline',
            },
            {
              value: 'referTorDVM',
              label: 'Refer to rDVM',
              hasAccess:
                hospital &&
                (hospital.speciality === 'Emergency' ||
                  hospital.speciality === 'Emergency & Specialty'),
            },
          ]}
        />
      </div>

      {!isEdit && isShowFeedback && (
        <div
          style={{
            marginTop: '10px',
          }}
        >
          <Row>
            <Col col={6}>
              <InputField
                placeholder="Add email"
                type="textarea-autosize"
                value={values?.survey?.email}
                label="Email for sending survey"
                name={surveyModal.feedback_email}
              />
            </Col>
          </Row>
        </div>
      )}
      <Dialog
        confirmText="Close"
        title="Please Note"
        buttons={(close) => (
          <StandardButton fullWidth text="Close" onClick={close} />
        )}
        text={
          <>
            <p>You have selected Administrative as the call outcome.</p>
          </>
        }
        open={
          isAdminAlert &&
          Config?.hideEmergencyCallOutcome
            ?.split(',')
            ?.includes(values.hospital_id + '')
        }
      />
      <CheckProtocolsInfo hospitalType="other">
        <CallOutcomeAction
          corporateOptions={corporateGroupOptions}
          isCorporateFetching={isCorporateGroupFetching}
        />

        <CallOutcomeRefer
          {...props}
          corporateOptions={corporateGroupOptions}
          isCorporateFetching={isCorporateGroupFetching}
        />
      </CheckProtocolsInfo>
    </FlexLayout>
  );
};
